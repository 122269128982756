import './About.css'
import html5 from '../../assets/html5.svg'
import css3 from '../../assets/css3.svg'
import javascript from '../../assets/javascript.svg'
import reactPic from '../../assets/react.svg'
import redux from '../../assets/redux.svg'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { HashLink as Link } from 'react-router-hash-link'

gsap.registerPlugin(ScrollTrigger)

export const About = () => {
  const techArray = [
    {
      pic: html5,
      text: 'HTML5'
    },
    {
      pic: css3,
      text: 'CSS3'
    },
    {
      pic: javascript,
      text: 'JavaScript'
    },
    {
      pic: reactPic,
      text: 'React js'
    },
    {
      pic: redux,
      text: 'Redux'
    },
    {
      text: 'Node js '
    },
    {
      text: 'Express js'
    },
    {
      text: 'MongoDB'
    },
    {
      text: 'Firebase'
    },
    {
      text: 'etc...'
    }
  ]

  const aboutRef = useRef([])

  useEffect(() => {
    gsap.fromTo(aboutRef.current, {
      autoAlpha: 0,
      y: +50,
    }, {
      duration: 1,
      autoAlpha: 1,
      y: 0,
      ease: 'SlowMo',
      scrollTrigger: {
        trigger: aboutRef.current,
        start: 'top 70%',
        toggleActions: 'play none none',
      }
    })
  }, [])

  return (
    <div className='about' ref={aboutRef} id='about'>
      <div className='about__left'>
        <h3>About me</h3>
        <p >
          I am a dedicated Frontend Developer with a passion for coding, problem solving and software architecture – looking forward to working as a full stack developer.
          </p>
        <p >
          I have made it my priority to utilize current technologies and new techniques to drive elegant, creative solutions across a range of projects. I have refined analytical and ‘outside the box’ thinking skills. After all, it is all about solving problems and creating awesome, meaningful products - for people to love!
          </p>
        <h4>Languages:</h4>
        <ul>
          <li>🇬🇧 ENGLISH - Fluent</li>
          <li>🇸🇪 SWEDISH - Basic communication</li>
          <li>🇵🇱 POLISH - Mother tongue</li>

        </ul>
        <div className='about__buttons' >
          <Link smooth to='/#projects'>
            <button>PROJECTS</button>
          </Link>
          <Link smooth to='/#contact'>
            <button className='outlined'>CONTACT</button>
          </Link>
        </div>

      </div>
      <div className='about__right'>
        <h3>Technologies</h3>
        <div className='about__tech'>
          {techArray.map((e, index) => (
            <div
              key={`tech${index}`}
              className='about__tech__major'
            >
              {e.pic && <img src={e.pic} alt='' />}
              <h4>{e.text}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}