import './App.css'
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom'

import { About } from './Components/About/About'
import { Hero } from './Components/Hero/Hero'
import { Projects } from './Components/Projects/Projects'
import { Contact } from './Components/Contact/Contact'

function App() {
  return (
    <div className="app">
      <Router>
        <Route path='/'>
          <Hero />
          <About />
          <Projects />
          <Contact />
        </Route>
      </Router>
    </div>
  )
}

export default App
