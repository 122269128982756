import './Hero.css'
import { useRef, useEffect } from 'react'
import heroImage from '../../assets/hero.jpg'
import { gsap } from 'gsap'
import { HashLink as Link } from 'react-router-hash-link'

export const Hero = () => {

  const titlesRef = useRef(null)
  const photoRef = useRef(null)

  useEffect(() => {
    gsap.from(titlesRef.current, {
      duration: 2,
      autoAlpha: 0,
      ease: 'slow',
      delay: 2
    })
  }, [])

  useEffect(() => {
    gsap.from(photoRef.current, {
      duration: 3,
      x: -100,
      autoAlpha: 0,
      ease: 'slow',
      delay: 0.5
    })
  }, [])

  return (
    <div className='hero'>
      <div ref={titlesRef} className='hero__left'>
        <h2>Hello, I am Karol Cislowski</h2>
        <h1>I am a Frontend Developer</h1>
        <div className='hero__buttons'>
          <Link smooth to='/#about'>
            <button>ABOUT ME</button>
          </Link>
          <Link smooth to='/#projects'>
            <button className='outlined'>PROJECTS</button>
          </Link>
        </div>

      </div>
      <img ref={photoRef}
        src={heroImage}
        alt='hero cover'
        className='hero__image'
      />
    </div>
  )
}