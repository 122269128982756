import './Contact.css'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'


export const Contact = () => {
  return (
    <div className='contact' id='contact' >
      <h3>CONTACT</h3>
      <div className='contact__icons'>
        <a href='mailto:cislowski.karol@gmail.com'>
          <MailOutlineIcon />
        </a>
        <a href='https://www.linkedin.com/in/karol-cislowski/'>
          <LinkedInIcon />
        </a>
        <a href='https://github.com/KarolCislowski'>
          <GitHubIcon />
        </a>
      </div>
    </div>
  )
}