import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import './Projects.css'
import alex from '../../assets/alex.png'
import trip from '../../assets/trip.png'
import fb from '../../assets/fb.png'
import air from '../../assets/airbnb-clone-screen.png'

gsap.registerPlugin(ScrollTrigger)

export const Projects = () => {

  const projects = [{
    img: alex,
    title: 'Aleksandra Swigon Städfirma',
    description: 'Simple React-based website built for a small, local cleaning company located in Stockholm. Delicate and elegant design with basic information and picture gallery - according to the client\'s wishes. Hosted on the client’s AWS Cloud and connected to custom domain.',
    linkHosted: 'http://aleksandraswigon.se/',
    linkRepo: null,
    testimonial: 'Highly recommended! Even though I have a small cleaning business, I still want to run it as professionally as possible. Now with my new website, every time when a potential client asks me about my services, I can simply send them there! I have all information needed there, my basic prices, as well as a beautiful gallery with a bunch of photos. I have seen a big improvement with my regular customers\' reviews and opinions thanks to the website. All of them said that the website is nicely designed, clear and easy to navigate. Now I feel much more comfortable and professional and I can advertise more in social media.',
  },
  {
    img: air,
    title: 'Airbnb UI Clone',
    description: 'Project created to learn Next.js, Tailwind.css and MapBox',
    linkHosted: 'https://cislowski-airbnb-clone.netlify.app/',
    linkRepo: 'https://github.com/KarolCislowski/Airbnb-clone',
    testimonial: null,
  },
  {
    img: trip,
    title: 'Trip Store',
    description: 'Private e-commerce project built as a test of Google\'s Firebase platform. Application contains most of the modern online shop functionalities and is even connected to the Stripe payment system (in a test mode, free of charge to try it!).',
    linkHosted: 'https://cislowski-shop2021.web.app/',
    linkRepo: 'https://github.com/KarolCislowski/shop2021',
    testimonial: null,
  },
  {
    img: fb,
    title: 'Facebook Clone - Under Construction',
    description: 'This is my playground project, where I am trying to reverse engineer a whole Facebook in my free time. Come and check out what I have managed to do until now!',
    linkHosted: 'https://cislowski-fb-clone.web.app/',
    linkRepo: 'https://github.com/KarolCislowski/fb-clone',
    testimonial: null,
  }
  ]

  const projectsRef = useRef([])

  useEffect(() => {
    gsap.fromTo(projectsRef.current, {
      autoAlpha: 0,
      y: +50,
    }, {
      duration: 1,
      autoAlpha: 1,
      y: 0,
      ease: 'SlowMo',
      scrollTrigger: {
        trigger: projectsRef.current,
        start: 'top 80%',
        toggleActions: 'play none none',
      }
    })
  }, [])

  return (
    <div className='projects' ref={projectsRef} id='projects'>
      <h3>Projects</h3>
      {projects.map((e, index) => (
        <div key={`project${index}`} className={(index % 2 === 0) ?
          'projects__project'
          :
          'projects__project projects__projectRight'}
        >
          <div className='projects__projectImg'>
            <a href={e.linkHosted}><img

              src={e.img}
              alt={e.title}
            /></a>
          </div>
          <div className='projects__projectDetails'>
            <h4>{e.title}</h4>
            <p>{e.description}</p>
            <a href={e.linkHosted}>Live</a>
            {e.linkRepo && <a href={e.linkRepo}>GitHub</a>}
            {e.testimonial && <>
              <h5>Client's Review</h5>
              <p>{e.testimonial}</p>
            </>}
          </div>
        </div>
      ))}
      <h4>Want more? Come and browse my <a href='https://github.com/KarolCislowski'>GitHub</a></h4>
    </div>
  )
}